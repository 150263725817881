import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import DataContext from '../../Contexts/DataContext';
import LocationInfo from '../../Components/LocationInfo/LocationInfo';
import LocationGroup from '../../Components/LocationGroup/LocationGroup';
import Utils from '../../utils';
import Constants from '../../constants';
import Storage from '../../storage';

/**
 * Represents the home page of the consumer app.
 */
const Home = (props) => {
  const context = useContext(DataContext);
  const { chainInfo, minimalLocationInfo } = context;
  let locPage = null;

  if (minimalLocationInfo?.siteId) {
    locPage = <LocationInfo history={props.history} />;
  } else if (chainInfo?.chainName) {
    const code = Utils.getUrlParam(Constants.voucherStorageKey);
    if (code) {
      Storage.setItem(Constants.voucherStorageKey, code);
    }
    locPage = <LocationGroup history={props.history} />;
  }

  return <div>{locPage}</div>;
};

export default withTranslation()(Home);
