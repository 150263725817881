import React, { Component, Fragment } from 'react';
import axios from 'axios';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Enums from '../../enums';
import Session from '../../session';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import PageviewBuilder from '../../pageviewBuilder';
import DataContext from '../../Contexts/DataContext';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Overlay from '../../Components/Overlay/Overlay';
import GoogleRating from '../../Components/GoogleRating/GoogleRating';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import Utils from '../../utils';
import './Download.scss';
import ContentManagementService from '../../Services/contentManagementService';

const eventBuilder = new EventBuilder();
const pageviewBuilder = new PageviewBuilder();

/**
 * Represents the Booking page of the consumer app.
 */
class Download extends Component {
  /**
   * Initializes a new instance of the Booking component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      showLoadingOverlay: true,
      isDateCurrent: false,
      selectedAppointmentTypeId: 0,
    };

    this._contentManagementService = new ContentManagementService();
  }

  /**
   * Executes when the component mounts to the DOM.
   */
  async componentDidMount() {
    
    const pathname = window.location.pathname;    
    let fileId = '';

    if (pathname) {
      const urlSegments = pathname.split('/');

      if (urlSegments && urlSegments.length > 0) {
        fileId = urlSegments[3];
      }
    }

    const locId = Utils.getUniqueLocationIdFromUrlPathName(window.location.pathname);
      
    

    eventBuilder
      .withCategory(eventBuilder.Category.Download.downloadPage)
      .withLabel(eventBuilder.Label.practiceIdentifier);

    pageviewBuilder.pageview(pageviewBuilder.Page.Booking.time);

    //Attempt to download file then redirect to store page
    let file = null;
    if(fileId) {
      file = await this._contentManagementService.downloadChatAttachment(fileId);
    }

    if(file) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = fileId;
      link.href = URL.createObjectURL(
        new Blob([file])
      );
      link.click();
    }

    window.location.pathname = Constants.homeRoute + locId;
  }

  /**
   * Renders the component.
   */
  render() {
    const { showLoadingOverlay } =
      this.state;
    const { t } = this.props;

    return (
      <div className="page">
        <section className="download__header">
          <Header
            eventBuilder={eventBuilder}
            onNavigateBack={this.onNavigateBack}
          />
        </section>
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}
export default withTranslation()(Download);
