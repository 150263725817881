// Dependencies
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Pages
import Root from '../Pages/Root/Root';
import Home from '../Pages/Home/Home';
import Resource from '../Pages/Resource/Resource';
import Booking from '../Pages/Booking/Booking';
import PersonalInfo from '../Pages/PersonalInfo/PersonalInfo';
import Details from '../Pages/Details/Details';
import Confirm from '../Pages/Confirm/Confirm';
import Terms from '../Pages/Legal/Terms';
import Privacy from '../Pages/Legal/Privacy';
import Feedback from '../Pages/Feedback/Feedback';
import StellestConfirmation from '../Pages/Stellest/StellestConfirmation';
import StellestReschedule from '../Pages/Stellest/StellestReschedule';
import StellestSmsLanding from '../Pages/Stellest/StellestSmsLanding';
import OptOutConfirmation from '../Pages/OptOut/OptOutConfirmation';
import SurveyResponse from '../Pages/SurveyResponse/SurveyResponse';
import PatientSurveyPage from '../Pages/PatientSurvey/PatientSurveyPage';
import Download from '../Pages/Download/Download';

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Root} />

        <Route exact path="/privacy" component={Privacy} />

        <Route exact path="/terms" component={Terms} />

        <Route exact path="/:uniqueLocationId" component={Home} />

        <Route path="/:uniqueLocationId/book/resource" component={Resource} />

        <Route path="/:uniqueLocationId/book/visit" component={Booking} />

        <Route
          path="/:uniqueLocationId/book/personal-info"
          component={PersonalInfo}
        />

        <Route
          path="/:uniqueLocationId/waitlist/personal-info"
          component={PersonalInfo}
        />

        <Route path="/:uniqueLocationId/book/details" component={Details} />

        <Route path="/:uniqueLocationId/book/confirm" component={Confirm} />

        <Route path="/:uniqueLocationId/feedback" component={Feedback} />

        <Route path="/:uniqueLocationId/download" component={Download} />

        <Route
          path="/:uniqueLocationId/stellest/confirm"
          component={StellestConfirmation}
        />

        <Route
          path="/:uniqueLocationId/stellest/reschedule"
          component={StellestReschedule}
        />

        <Route
          path="/:uniqueLocationId/stellest/smsLanding"
          component={StellestSmsLanding}
        />

        <Route
          path="/:uniqueLocationId/optout/confirmation"
          component={OptOutConfirmation}
        />

        <Route
          path="/:uniqueLocationId/surveyResponse/thankYou"
          component={SurveyResponse}
        />

        <Route
          path="/:uniqueLocationId/patientSurvey"
          component={PatientSurveyPage}
        />
      </Switch>
    </Router>
  );
}
